import { configureStore } from '@reduxjs/toolkit';
import { apiRoot } from '@th-common/store/api';
import authReducer from '@th-common/store/auth/slice';
import commonStoreReducer from '@th-common/store/common/slice';
import fleetReducer from '@th-common/store/fleet/slice';
import geographicalQueryReducer from '@th-common/store/geographical-query/slice';
import jobReducer from '@th-common/store/job/slice';
import jobWizardReducer from '@th-common/store/job-wizard/slice';
import lookupReducer from '@th-common/store/lookup/slice';
import { authListenerMiddleware } from '@th-common/store/middleware';
import notificationReducer from '@th-common/store/notification/slice';
import systemStoreReducer from '@th-common/store/system/slice';
import userProfileReducer from '@th-common/store/user-profile/slice';
import videoPlaybackReducer from '@th-common/store/video-playback/slice';
import videoQueryReducer from '@th-common/store/video-query/slice';

import cameraVerificationReducer from '@/app/app/fleets/modals/DeviceDetailsModal/stores/camera-verification/slice';
import jobHistoryReducer from '@/app/app/fleets/modals/DeviceDetailsModal/stores/job-history/slice';
import logbookStoreReducer from '@/app/app/fleets/modals/DeviceDetailsModal/stores/logbook/slice';
import statusHistoryReducer from '@/app/app/fleets/modals/DeviceDetailsModal/stores/status-history/slice';
import geographicalQueryDetailsReducer from '@/app/app/jobs/geographical-queries/modals/GeographicalQueryDetails/stores/geographical-query-details/slice';
import jobExecutionsReducer from '@/app/app/jobs/log/modals/JobHistoryDetails/stores/job-execution/slice';

const store = configureStore({
  reducer: {
    [apiRoot.reducerPath]: apiRoot.reducer,

    auth: authReducer,
    common: commonStoreReducer,
    fleet: fleetReducer,
    job: jobReducer,
    jobHistory: jobHistoryReducer,
    jobExecutions: jobExecutionsReducer,
    logbook: logbookStoreReducer,
    notification: notificationReducer,
    statusHistory: statusHistoryReducer,
    system: systemStoreReducer,
    userProfile: userProfileReducer,
    cameraVerification: cameraVerificationReducer,
    jobWizard: jobWizardReducer,
    videoQuery: videoQueryReducer,
    lookup: lookupReducer,
    geographicalQuery: geographicalQueryReducer,
    geographicalQueryDetails: geographicalQueryDetailsReducer,
    videoPlayback: videoPlaybackReducer,
  },

  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }).concat(apiRoot.middleware, authListenerMiddleware.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
