/* eslint-disable no-param-reassign */
import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { jobApi } from '@th-common/api/job.api';
import { dateKindToDate } from '@th-common/components/form/DateKindPicker/index.helpers';
import { TDateKind } from '@th-common/enums/date-kind.enum';
import { IPagedResult } from '@th-common/interfaces/paged-result';
import {
  IVideoQuery,
  IVideoQueryFilters,
  IVideoQueryFiltersFormValues,
  IVideoQuerySearchRequest,
  TVideoQueryFiltered,
} from '@th-common/interfaces/video-query';
import { DateTimeUtils } from '@th-common/utils';
import dayjs from 'dayjs';

import { api as apiVideoQuery } from './api';

function convertFormValuesDateToSearchRequestDate(
  { fromDate, toDate }: Pick<IVideoQueryFiltersFormValues, 'fromDate' | 'toDate'>,
): Pick<IVideoQueryFilters, 'fromDate' | 'toDate'> {
  return {
    fromDate: DateTimeUtils.onlyDateRequest(fromDate),
    toDate: DateTimeUtils.onlyDateRequest(toDate),
  };
}

function convertFormValueToSearchRequestFilters(formValues: IVideoQueryFiltersFormValues): IVideoQueryFilters {
  const userKey = formValues.userKey === 'All' || formValues.userKey === 'MineOnly' ? null : formValues.userKey;
  const mineOnly = formValues.userKey === 'MineOnly';

  return {
    mineOnly,
    userKey,
    jobExecutionStatus: formValues.jobExecutionStatus === 'All' ? null : formValues.jobExecutionStatus,
    ...convertFormValuesDateToSearchRequestDate(formValues),
  };
}

interface ISetFilterDatePayload {
  payload: Pick<IVideoQueryFiltersFormValues, 'fromDate' | 'toDate'>;
}

export interface IVideoQueryState {
  videoQueries: IVideoQuery[];
  searchRequest: IVideoQuerySearchRequest;
  pagination: Omit<IPagedResult<IVideoQuery>, 'items'>;
  filterDateKind: TDateKind;
  lastTimeUpdated: string;
  selectedVideoQuery: IVideoQuery | null;
  navigationInProgress: boolean;
}

const [defaultFromDate, defaultToDate] = dateKindToDate(TDateKind.AllTime);

export const defaultFilters: TVideoQueryFiltered = {
  userKey: null,
  jobExecutionStatus: null,
  fromDate: DateTimeUtils.onlyDateRequest(defaultFromDate),
  toDate: DateTimeUtils.onlyDateRequest(defaultToDate),
  orderBy: [{
    fieldName: 'createTime',
    ascending: false,
  }],
};

const initialState: IVideoQueryState = {
  videoQueries: [],
  searchRequest: {
    page: 1,
    pageSize: 36,
    searches: [],
    searchAsOneWord: true,
    mineOnly: false,
    ...defaultFilters,
  },
  filterDateKind: TDateKind.AllTime,
  pagination: {
    page: 1,
    pageCount: 0,
    totalCount: 0,
  },
  lastTimeUpdated: dayjs().toISOString(),
  selectedVideoQuery: null,
  navigationInProgress: false,
};

export const slice = createSlice({
  name: 'videoQuery',
  initialState,
  reducers: {
    reset: () => ({
      ...initialState,
      lastTimeUpdated: dayjs().toISOString(),
    }),
    resetPage: (state) => {
      state.videoQueries = [];
      state.searchRequest.page = 1;
      state.selectedVideoQuery = null;
    },
    setPage: (state, { payload }: { payload: number }) => {
      state.searchRequest.page = payload;
      state.selectedVideoQuery = null;
    },
    formFilter: (state, { payload }) => {
      const { dateKind, ...filters } = payload;

      state.searchRequest = {
        ...state.searchRequest,
        page: 1,
        ...convertFormValueToSearchRequestFilters(filters),
      };

      state.filterDateKind = dateKind;
      state.selectedVideoQuery = null;
    },
    setFilterDate: (state, { payload }: ISetFilterDatePayload) => {
      const { fromDate, toDate } = convertFormValuesDateToSearchRequestDate(payload);

      state.searchRequest = {
        ...state.searchRequest,
        page: 1,
        fromDate,
        toDate,
      };
      state.selectedVideoQuery = null;
    },
    setFilterDateKind: (state, { payload }: { payload: TDateKind }) => {
      state.filterDateKind = payload;
      state.selectedVideoQuery = null;
    },
    search: (state, { payload }) => {
      state.searchRequest = {
        ...state.searchRequest,
        page: 1,
        searches: payload ? [payload] : [],
      };
      state.selectedVideoQuery = null;
    },
    selectVideoQuery: (state, { payload }: { payload: IVideoQuery | null }) => {
      state.selectedVideoQuery = payload;
    },
    navigationInProgress: (state, { payload }: { payload: boolean }) => {
      state.navigationInProgress = payload;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<IVideoQueryState>) => {
    builder
      .addMatcher(apiVideoQuery.endpoints.videoQueriesAll.matchFulfilled, (state, { payload }) => {
        const { items, ...pagination } = payload;

        state.videoQueries = items || [];
        state.pagination = pagination;
        state.lastTimeUpdated = dayjs().toISOString();

        if (state.selectedVideoQuery && !state.videoQueries.find((vq) => vq.id === state.selectedVideoQuery?.id)) {
          state.selectedVideoQuery = null;
        }
      })
      .addMatcher(jobApi.endpoints.getVideoQueryInfo.matchFulfilled, (state, { payload }) => {
        state.selectedVideoQuery = {
          ...state.selectedVideoQuery!,
          details: payload,
        };
      });
  },
});

export default slice.reducer;
