import { TJobType } from '@th-common/enums/job/job-type.enum';
import { TReduxTag } from '@th-common/enums/redux-tags.enum';
import { IPagedResult } from '@th-common/interfaces/paged-result';
import { IVideoQuery, IVideoQuerySearchRequest } from '@th-common/interfaces/video-query';
import { apiRoot } from '@th-common/store/api';

export type TVideoQueryJobTypes = TJobType.UserDownload | TJobType.RidershipQuery | TJobType.GeographicalQuery;

interface IVideoQueryAllParams {
  jobType: TVideoQueryJobTypes;
  searchRequest: IVideoQuerySearchRequest;
}

export const api = apiRoot.enhanceEndpoints({}).injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    videoQueriesAll: builder.query<IPagedResult<IVideoQuery>, IVideoQueryAllParams>({
      query: ({
        jobType,
        searchRequest,
      }) => {
        let endpoint = '';
        switch (jobType) {
          case TJobType.UserDownload:
            endpoint = 'video-queries';
            break;
          case TJobType.RidershipQuery:
            endpoint = 'ridership-validations';
            break;
        }

        return {
          url: `job/${endpoint}/all`,
          method: 'POST',
          body: searchRequest,
        };
      },
      providesTags: [
        TReduxTag.VideoQueries,
        TReduxTag.RidershipValidations,
      ],
    }),
  }),
});
