import { IGeographicalQueryDeviceExecution, IGeographicalQueryDeviceExecutionInfo, IGeographicalQueryJob } from '@th-common/interfaces/geographical-query-job';
import { apiRoot } from '@th-common/store/api';

export const geographicalQueryDetailsApi = apiRoot.enhanceEndpoints({}).injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getGeographicalQueryJob: builder.query<IGeographicalQueryJob, {
      geographicalQueryId: number;
    }>({
      query: ({
        geographicalQueryId,
      }) => {
        return {
          url: `job/geographical-queries/${geographicalQueryId}`,
        };
      },
    }),
    getGeographicalQueryDeviceExecutions: builder.query<IGeographicalQueryDeviceExecution[], {
      geographicalQueryId: number;
    }>({
      query: ({
        geographicalQueryId,
      }) => {
        return {
          url: `job/geographical-queries/${geographicalQueryId}/device-executions`,
        };
      },
    }),
    getGeographicalQueryDeviceExecutionInfo: builder.query<IGeographicalQueryDeviceExecutionInfo, {
      geographicalQueryId: number;
      jobExecutionId: number;
    }>({
      query: ({
        geographicalQueryId,
        jobExecutionId,
      }) => {
        return {
          url: `job/geographical-queries/${geographicalQueryId}/device-executions/${jobExecutionId}/info`,
        };
      },
    }),
  }),
});
