import { TReduxTag } from '@th-common/enums/redux-tags.enum';
import { IJob, IJobSearchRequest } from '@th-common/interfaces/job';
import { IPagedResult } from '@th-common/interfaces/paged-result';
import { apiRoot } from '@th-common/store/api';

export const api = apiRoot.enhanceEndpoints({}).injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getJobs: builder.query<IPagedResult<IJob>, IJobSearchRequest>({
      query: (searchRequest: IJobSearchRequest) => {
        return {
          url: 'job/all',
          method: 'POST',
          body: searchRequest,
        };
      },
      providesTags: [
        TReduxTag.Jobs,
      ],
    }),
  }),
});
