'use client';

// This is a client component

// Import the next/image component
import React, { CSSProperties, JSX } from 'react';
import { ReactSVG } from 'react-svg';

import spritePath from '../../public/sprite/sprite.svg';

const styles: CSSProperties = {
  height: 0,
  opacity: 0,
  display: 'none',
};

const SvgSprite: React.FC = (): JSX.Element => (
  <div style={styles}>
    <ReactSVG
      src={spritePath.src}
      beforeInjection={(svg) => {
        // Get all child elements of the SVGSVGElement
        const elementsWithId = svg.querySelectorAll('[id]');

        // Iterate over the elements and update the id attribute
        elementsWithId.forEach((element) => {
          element.setAttribute('id', `icon-${element.id}`);
        });

        return svg;
      }}
    />
  </div>
);

export default SvgSprite;
