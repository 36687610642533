import { TJobType } from '@th-common/enums/job/job-type.enum';
import { TNetworkScope } from '@th-common/enums/job/network-scope.enum';
import { TReduxTag } from '@th-common/enums/redux-tags.enum';
import {
  IJob,
  IJobVideoQueryBody,
  IJobVideoQueryInfo,
  IJobVideoQueryRequest,
  IScheduleJobBody,
  IScheduleJobRequest,
} from '@th-common/interfaces/job';
import { IVideoQueryInfoParams } from '@th-common/interfaces/video-query';
import { apiRoot } from '@th-common/store/api';
import { TVideoQueryJobTypes } from '@th-common/store/video-query/api';

export type TScheduleJobTypes =
TJobType.LogbookUpdate |
TJobType.EventDownload |
TJobType.TimeSync |
TJobType.ConfigUpdate |
TJobType.StatusUpdate |
TJobType.FirmwareUpdate |
TJobType.CameraVerification |
TJobType.ConfigPush;

interface IVideoQueryDetailsParams {
  jobId: number;
  jobType: TVideoQueryJobTypes;
}

interface IScheduleJobDetailsParams {
  jobId: number;
  jobType: TScheduleJobTypes;
}

interface IScheduleJobManagementParams {
  jobType: TScheduleJobTypes;
  jobRequest: IScheduleJobBody;
}

const getScheduleJobEndpoint = (jobType: TScheduleJobTypes): string => {
  switch (jobType) {
    case TJobType.LogbookUpdate:
      return 'logbook-updates';
    case TJobType.EventDownload:
      return 'event-downloads';
    case TJobType.TimeSync:
      return 'time-synchronizations';
    case TJobType.ConfigUpdate:
      return 'sync-configurations';
    case TJobType.StatusUpdate:
      return 'status-updates';
    case TJobType.FirmwareUpdate:
      return 'firmware-updates';
    case TJobType.CameraVerification:
      return 'camera-verifications';
    case TJobType.ConfigPush:
      return 'push-configurations';
  }
};

const getVideoQueryEndpoint = (jobType: TVideoQueryJobTypes): string => {
  switch (jobType) {
    case TJobType.UserDownload:
      return 'video-queries';
    case TJobType.RidershipQuery:
      return 'ridership-validations';
    case TJobType.GeographicalQuery:
      return 'geographical-queries';
  }
};

export const jobApi = apiRoot.enhanceEndpoints({}).injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getVideoQuery: builder.query<IJobVideoQueryRequest, IVideoQueryDetailsParams>({
      query: ({
        jobId,
        jobType,
      }) => `job/${getVideoQueryEndpoint(jobType)}/${jobId}`,
    }),
    getVideoQueryInfo: builder.query<IJobVideoQueryInfo, IVideoQueryInfoParams>({
      query: ({
        jobId,
        jobType,
      }) => {
        return `job/${getVideoQueryEndpoint(jobType)}/${jobId}/info`;
      },
      providesTags: [
        TReduxTag.VideoQueries,
        TReduxTag.RidershipValidations,
        TReduxTag.GeographicalQueries,
      ],
    }),
    createVideoQuery: builder.mutation<IJob, IJobVideoQueryBody>({
      query: (body) => ({
        url: 'job/video-queries',
        method: 'POST',
        body,
      }),
      invalidatesTags: [TReduxTag.VideoQueries],
    }),
    updateVideoQuery: builder.mutation<IJob, Omit<IJobVideoQueryBody, 'deviceIds'> & { id: number }>({
      query: (body) => ({
        url: 'job/video-queries',
        method: 'PUT',
        body,
      }),
      invalidatesTags: [TReduxTag.VideoQueries],
    }),
    deleteJob: builder.mutation<IJob, number>({
      query: (jobId) => ({
        url: `job/${jobId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        TReduxTag.VideoQueries,
        TReduxTag.RidershipValidations,
        TReduxTag.GeographicalQueries,
        TReduxTag.Jobs,
      ],
    }),
    createRidershipValidation: builder.mutation<IJob, IJobVideoQueryBody>({
      query: (body) => ({
        url: 'job/ridership-validations',
        method: 'POST',
        body,
      }),
      invalidatesTags: [TReduxTag.RidershipValidations],
    }),
    updateRidershipValidation: builder.mutation<IJob, Omit<IJobVideoQueryBody, 'deviceIds'> & { id: number }>({
      query: (body) => ({
        url: 'job/ridership-validations',
        method: 'PUT',
        body,
      }),
      invalidatesTags: [TReduxTag.RidershipValidations],
    }),
    createGeographicalValidation: builder.mutation<IJob, IJobVideoQueryBody>({
      query: (body) => ({
        url: 'job/geographical-queries',
        method: 'POST',
        body,
      }),
      invalidatesTags: [TReduxTag.GeographicalQueries],
    }),
    updateGeographicalValidation: builder.mutation<IJob, Omit<IJobVideoQueryBody, 'deviceIds'> & { id: number }>({
      query: (body) => ({
        url: 'job/geographical-queries',
        method: 'PUT',
        body,
      }),
      invalidatesTags: [TReduxTag.GeographicalQueries],
    }),
    setActive: builder.mutation<IJob, number>({
      query: (jobId) => ({
        url: `job/${jobId}/activation`,
        method: 'POST',
      }),
      invalidatesTags: [
        TReduxTag.VideoQueries,
        TReduxTag.RidershipValidations,
        TReduxTag.GeographicalQueries,
        TReduxTag.Jobs,
      ],
    }),
    setInactive: builder.mutation<IJob, number>({
      query: (jobId) => ({
        url: `job/${jobId}/deactivation`,
        method: 'POST',
      }),
      invalidatesTags: [
        TReduxTag.VideoQueries,
        TReduxTag.RidershipValidations,
        TReduxTag.GeographicalQueries,
        TReduxTag.Jobs,
      ],
    }),
    changeNetwork: builder.mutation<IJob, { jobId: number; networkScope: TNetworkScope }>({
      query: ({ jobId, networkScope }) => ({
        url: 'job/network-scope-change',
        method: 'PUT',
        body: {
          id: jobId,
          networkScope,
        },
      }),
      invalidatesTags: [
        TReduxTag.VideoQueries,
        TReduxTag.RidershipValidations,
        TReduxTag.GeographicalQueries,
        TReduxTag.Jobs,
      ],
    }),
    getScheduleJob: builder.query<IScheduleJobRequest, IScheduleJobDetailsParams>({
      query: ({
        jobId,
        jobType,
      }) => `job/${getScheduleJobEndpoint(jobType)}/${jobId}`,
    }),
    createScheduleJob: builder.mutation<IJob, IScheduleJobManagementParams>({
      query: ({ jobType, jobRequest }) => ({
        url: `job/${getScheduleJobEndpoint(jobType)}`,
        method: 'POST',
        body: jobRequest,
      }),
      invalidatesTags: [
        TReduxTag.Jobs,
      ],
    }),
    updateScheduleJob: builder.mutation<IJob, IScheduleJobManagementParams>({
      query: ({ jobType, jobRequest }) => ({
        url: `job/${getScheduleJobEndpoint(jobType)}`,
        method: 'PUT',
        body: jobRequest,
      }),
      invalidatesTags: [
        TReduxTag.Jobs,
      ],
    }),
  }),
});
