'use client';

import { LicenseInfo } from '@mui/x-license';
import { config } from '@th-common/utils/config';

LicenseInfo.setLicenseKey(config.muiXLicenseKey);

const MuiXLicense = (): null => {
  return null;
};

export default MuiXLicense;
