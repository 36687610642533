/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const slice = createSlice({
  name: 'lookup',
  initialState,
  reducers: {
    reset: () => initialState,
  },
});

export default slice.reducer;
