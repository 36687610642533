import { FC } from 'react';
import { DateRange } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';
import { addMethod, array, object } from 'yup';

addMethod(object, 'dayjs', (message) => {
  return object().test('dayjs', message, (value: any): boolean => {
    if (!value) {
      return true;
    }
    return dayjs.isDayjs(value);
  });
});

addMethod(array, 'dayjs', (message) => {
  return array().test('dayjs', message, (value: Dayjs[] | undefined): boolean => {
    const [fromDate, toDate] = value as DateRange<Dayjs>;
    return dayjs.isDayjs(fromDate) && dayjs.isDayjs(toDate);
  });
});

const YupProvider: FC<unknown> = (): null => {
  return null;
};
export default YupProvider;
