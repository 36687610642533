import { ICamera, ICameraCurrentImage, ICameraReferenceImage } from '@th-common/interfaces/camera';
import { apiRoot } from '@th-common/store/api';

export const api = apiRoot.enhanceEndpoints({}).injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getCameras: builder.query<ICamera[], number>({
      query: (deviceId) => `device/${deviceId}/cameraimages`,
    }),
    acceptImage: builder.mutation<ICameraCurrentImage, number>({
      query: (imageId) => {
        return {
          url: `cameraimage/${imageId}/acceptance`,
          method: 'POST',
        };
      },
    }),
    rejectImage: builder.mutation<ICameraCurrentImage, number>({
      query: (imageId) => {
        return {
          url: `cameraimage/${imageId}/rejection`,
          method: 'POST',
        };
      },
    }),
    setAsReference: builder.mutation<ICameraReferenceImage, number>({
      query: (imageId) => {
        return {
          url: `cameraimage/${imageId}/set-as-reference`,
          method: 'POST',
        };
      },
    }),
  }),
});
