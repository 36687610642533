import { api } from './api';
import { selectors } from './selectors';
import { slice } from './slice';

interface ILookupStore {
  actions: typeof slice.actions;
  selectors: typeof selectors;
  api: typeof api;
}

const lookupStore: ILookupStore = {
  actions: slice.actions,
  selectors,
  api,
};

export { lookupStore };
