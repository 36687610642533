import { IIdentity, IUsernames } from '@th-common/interfaces/lookup';
import { apiRoot } from '@th-common/store/api';

export const api = apiRoot.enhanceEndpoints({}).injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    usernames: builder.query<IUsernames[], void>({
      query: () => 'lookup/usernames',
    }),
    firmwareVersions: builder.query<IIdentity[], void>({
      query: () => 'lookup/firmware-versions',
    }),
    templateConfigurations: builder.query<IIdentity[], void>({
      query: () => 'lookup/template-configurations',
    }),
  }),
});
