import { IJobHistory, IJobHistorySearchRequest } from '@th-common/interfaces/job-history';
import { IPagedResult } from '@th-common/interfaces/paged-result';
import { apiRoot } from '@th-common/store/api';

export const api = apiRoot.enhanceEndpoints({}).injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getJobHistory: builder.query<IPagedResult<IJobHistory>, {
      deviceId: number;
      searchRequest: IJobHistorySearchRequest;
    }>({
      query: ({
        deviceId,
        searchRequest,
      }) => {
        return {
          url: `device/${deviceId}/job-history/all`,
          method: 'POST',
          body: searchRequest,
        };
      },
    }),
  }),
});
