import { ILogbook, ILogbookSearchRequest } from '@th-common/interfaces/logbook';
import { IPagedResult } from '@th-common/interfaces/paged-result';
import { apiRoot } from '@th-common/store/api';

export const api = apiRoot.enhanceEndpoints({}).injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getLogbook: builder.query<IPagedResult<ILogbook>, { deviceId: number; searchRequest: ILogbookSearchRequest }>({
      query: ({ deviceId, searchRequest }) => {
        return {
          url: `device/${deviceId}/log-book/all`,
          method: 'POST',
          body: searchRequest,
        };
      },
    }),
    exportLogbook: builder.mutation<Blob, { deviceId: number; searchRequest: ILogbookSearchRequest }>({
      query: ({ deviceId, searchRequest }) => ({
        url: `device/${deviceId}/log-book/export`,
        method: 'POST',
        body: searchRequest,
        responseHandler: async (response) => {
          const blob = await response.blob();
          return blob;
        },
        cache: 'no-cache',
      }),
    }),
  }),
});
