export enum TAuthFeature {
  ViewLiveImages = 1,
  ViewStoredImages = 2,
  ReviewExportImages = 3,
  ReviewExportSequences = 4,
  ManageUsers = 5,
  ReviewPrintImages = 6,
  ViewDeviceLog = 7,
  ViewSoftwareLog = 8,
  ReviewAddMarker = 9,
  ReviewDeleteMarker = 10,
  ConfigureDevice = 11,
  ConfigExport = 12,
  ApplyConfig = 13,
  ViewStatus = 14,
  ViewService = 15,
  ServiceSyncTime = 16,
  ServiceFormatDisk = 17,
  ServiceUpdateFirmware = 18,
  ServiceReboot = 19,
  ExportLogbook = 20,
  ReviewExportAVI = 21,
  ReviewModifyTrack = 22,
  ReviewDeleteTrack = 23,
  LiveExportImages = 24,
  LivePrintImages = 25,
  EditUsers = 26,
  EditRoles = 27,
  ChangePassword = 28,
  DefaultSettings = 29,
  ConfigImport = 30,
  ConfigExpertMode = 31,
  AddVehicleToFleet = 32,
  RemoveVehicleFromFleet = 33,
  ViewFleetDatabase = 34,
  ViewFleetStatus = 35,
  ViewJobs = 36,
  AddEditJobs = 37,
  DeleteJobs = 38,
  VideoQueries = 39,
  ViewVideoQueries = 40,
  RequestVideoQueries = 41,
  DeleteVideoQueries = 42,
  ConfigureClientServerSettings = 43,
  ServiceViewDiagnostics = 44,
  ServiceViewStatus = 45,
  ServiceViewLog = 46,
  SetReferenceImage = 47,
  AcceptRejectVerificationImage = 48,
  ShowDebugInfo = 49,
  ManageTemplateConfigs = 50,
  AssetManagement = 51,
  AssetManagementViewAssets = 52,
  AssetManagementEditAssets = 53,
  AssetManagementManageTemplates = 54,
  VideoStorageManagement = 55,
  RidershipValidation = 56,
  OverrideJobNetworkScope = 57,
  GeographicalQueries = 58,
  ViewLiveImagesOverCellular = 59,
  ViewStoredImagesOverCellular = 60,
  ChangeDownloadJobNetworkScope = 61,
  ReviewManageBlurring = 62,
  ViewCameraStatistics = 63,
}
