import React, { JSX } from 'react';
import { useSelector } from 'react-redux';
import { defineAbilityFor } from '@th-common/guards/ability';
import { AbilityContext } from '@th-common/guards/can.guard';
import { authStore, systemStore } from '@th-common/store';

const AbilityProvider = (props: { children: React.ReactNode }): JSX.Element => {
  const features = useSelector(authStore.selectors.features);
  const authMethod = useSelector(authStore.selectors.authMethod);
  const lincensedFeatures = useSelector(systemStore.selectors.licensedFeatures);

  const ability = defineAbilityFor(features, lincensedFeatures, authMethod);

  return (
    <AbilityContext.Provider value={ability}>
      {props.children}
    </AbilityContext.Provider>
  );
};

export default AbilityProvider;
