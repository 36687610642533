import { TDateKind } from '@th-common/enums/date-kind.enum';
import { DateTimeUtils } from '@th-common/utils/dateTime';
import dayjs, { Dayjs } from 'dayjs';

export const dateKindToDate = (dateKind: TDateKind): [Dayjs, Dayjs] => {
  switch (dateKind) {
    case TDateKind.Recent:
      return [
        DateTimeUtils.last3Weeks,
        dayjs().endOf('day'),
      ];
    case TDateKind.AllTime:
      return [
        DateTimeUtils.longTimeAgoDate,
        dayjs().endOf('day'),
      ];
    case TDateKind.Yesterday:
      return [
        DateTimeUtils.longTimeAgoDate,
        dayjs().subtract(1, 'day').endOf('day'),
      ];
    case TDateKind.LastWeek:
      return [
        DateTimeUtils.longTimeAgoDate,
        dayjs().subtract(1, 'week').endOf('day'),
      ];
    case TDateKind.LastMonth:
      return [
        DateTimeUtils.longTimeAgoDate,
        dayjs().subtract(1, 'month').endOf('day'),
      ];
    case TDateKind.ForDate:
      return [
        DateTimeUtils.longTimeAgoDate,
        dayjs().endOf('day'),
      ];
    default:
      return [
        DateTimeUtils.last3Weeks,
        dayjs().endOf('day'),
      ];
  }
};
