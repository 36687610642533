import { IPagedResult } from '@th-common/interfaces/paged-result';
import { IStatusHistory, IStatusHistoryDetails, IStatusHistorySearchRequest } from '@th-common/interfaces/status-history';
import { apiRoot } from '@th-common/store/api';

interface IStatusHistoryParams {
  deviceId: number;
  searchRequest: IStatusHistorySearchRequest;
}

export const api = apiRoot.enhanceEndpoints({}).injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getStatusHistory: builder.query<IPagedResult<IStatusHistory>, IStatusHistoryParams>({
      query: ({
        deviceId,
        searchRequest,
      }) => {
        return {
          url: `device/${deviceId}/status-history/all`,
          method: 'POST',
          body: searchRequest,
        };
      },
    }),
    getStatusDetails: builder.query<IStatusHistoryDetails, { deviceId: number; statusId: number }>({
      query: ({
        deviceId,
        statusId,
      }) => `device/${deviceId}/status-history/${statusId}`,
    }),
  }),
});
