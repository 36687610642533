/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { TViewLayout } from '@th-common/interfaces/player/player';
import { IVideoSourceInfo } from '@th-common/interfaces/player/video-source-info';
import { IVideoBookmark } from '@th-common/interfaces/video/bookmark';
import dayjs from 'dayjs';

export interface IVideoPlaybackState {
  currentDateTime: string;
  clipDateTime: [string, string];
  videoRequest: {
    id: number;
    start: string;
    end: string;
    cameras: IVideoSourceInfo[];
  };
  maxFps: number;
  bookmarks: IVideoBookmark[];
  activeBookmarkIndex: number | null;
  viewLayout: TViewLayout;
}

const startTime = dayjs()
  .set('h', 11)
  .set('m', 30)
  .set('seconds', 0)
  .set('millisecond', 0);

const initialState: IVideoPlaybackState = {
  currentDateTime: startTime.toISOString(),
  clipDateTime: [startTime.toISOString(), startTime.toISOString()],
  videoRequest: {
    id: 123,
    start: startTime.toISOString(),
    end: startTime.add(600, 'second').toISOString(),
    cameras: [
      {
        name: 'Forward Facing',
        framerate: 15,
        severity: 1,
        src: 'https://tsi-video.trailheadtechnology.com/videos/AnnArbor20Cams/AnnArbor20Cams-CAMERA17/master.m3u8',
        isPlayerHide: false,
      },
      {
        name: 'Driver',
        framerate: 15,
        severity: 1,
        src: 'https://tsi-video.trailheadtechnology.com/videos/AnnArbor20Cams/AnnArbor20Cams-CAMERA18/master.m3u8',
        isPlayerHide: false,
      },
      {
        name: 'Front Door',
        framerate: 15,
        severity: 1,
        src: 'https://tsi-video.trailheadtechnology.com/videos/AnnArbor20Cams/AnnArbor20Cams-CAMERA19/master.m3u8',
        isPlayerHide: false,
      },
      {
        name: 'Front 360 - Boarding Area',
        framerate: 15,
        severity: 1,
        src: 'https://tsi-video.trailheadtechnology.com/videos/AnnArbor20Cams/AnnArbor20Cams-CAMERA20/master.m3u8',
        isPlayerHide: false,
      },
      {
        name: 'Front 360 - Wheelchair',
        framerate: 5,
        severity: 1,
        src: 'https://tsi-video.trailheadtechnology.com/videos/AnnArbor20Cams/AnnArbor20Cams-CAMERA21/master.m3u8',
        isPlayerHide: false,
      },
      {
        name: 'Front 360 - Front Seating',
        framerate: 5,
        severity: 1,
        src: 'https://tsi-video.trailheadtechnology.com/videos/AnnArbor20Cams/AnnArbor20Cams-CAMERA22/master.m3u8',
        isPlayerHide: false,
      },
      {
        name: 'Front to Rear',
        framerate: 5,
        severity: 1,
        src: 'https://tsi-video.trailheadtechnology.com/videos/AnnArbor20Cams/AnnArbor20Cams-CAMERA23/master.m3u8',
        isPlayerHide: false,
      },
      {
        name: 'Rear 360 - Rear to Front',
        framerate: 5,
        severity: 1,
        src: 'https://tsi-video.trailheadtechnology.com/videos/AnnArbor20Cams/AnnArbor20Cams-CAMERA24/master.m3u8',
        isPlayerHide: false,
      },
      {
        name: 'Rear 360 - Rear Door',
        framerate: 5,
        severity: 1,
        src: 'https://tsi-video.trailheadtechnology.com/videos/AnnArbor20Cams/AnnArbor20Cams-CAMERA25/master.m3u8',
        isPlayerHide: false,
      },
      {
        name: 'Rear 360 - Rear Entry Way',
        framerate: 5,
        severity: 1,
        src: 'https://tsi-video.trailheadtechnology.com/videos/AnnArbor20Cams/AnnArbor20Cams-CAMERA26/master.m3u8',
        isPlayerHide: false,
      },
      {
        name: 'Rear 360 - Rear Seating',
        framerate: 5,
        severity: 1,
        src: 'https://tsi-video.trailheadtechnology.com/videos/AnnArbor20Cams/AnnArbor20Cams-CAMERA27/master.m3u8',
        isPlayerHide: false,
      },
      {
        name: 'Street Side Rear',
        framerate: 15,
        severity: 1,
        src: 'https://tsi-video.trailheadtechnology.com/videos/AnnArbor20Cams/AnnArbor20Cams-CAMERA28/master.m3u8',
        isPlayerHide: false,
      },
    ],
  },
  maxFps: 15,
  bookmarks: [],
  activeBookmarkIndex: null,
  viewLayout: TViewLayout.Grid,
};

export const slice = createSlice({
  name: 'videoPlayback',
  initialState,
  reducers: {
    setCurrentTime: (state, { payload }: { payload: number }) => {
      state.currentDateTime = dayjs(state.videoRequest.start).add(payload, 'second').toISOString();
    },
    setCurrentDateTime: (state, { payload }: { payload: string }) => {
      state.currentDateTime = payload;
    },
    setClipValue: (state, { payload }: { payload: [number, number] }) => {
      state.clipDateTime = [
        dayjs(state.videoRequest.start).add(payload[0] / state.maxFps, 'second').toISOString(),
        dayjs(state.videoRequest.start).add(payload[1] / state.maxFps, 'second').toISOString(),
      ];
    },
    setCenterFrame: (state) => {
      const videoRequestMax = dayjs(state.videoRequest.end).diff(dayjs(state.videoRequest.start), 'seconds') * state.maxFps;
      const middleFrame = Math.floor(videoRequestMax / 2);
      state.currentDateTime = dayjs(state.videoRequest.start).add(middleFrame / state.maxFps, 'second').toISOString();
    },
    addBookmark: (state) => {
      const bookmark = {
        value: state.currentDateTime,
        saved: false,
      };

      if (state.activeBookmarkIndex !== null) {
        const activeBookmark = state.bookmarks[state.activeBookmarkIndex];
        if (activeBookmark.saved) {
          state.bookmarks = [
            ...state.bookmarks,
            bookmark,
          ];
          state.activeBookmarkIndex = state.bookmarks.length - 1;
          return;
        }
        state.bookmarks[state.activeBookmarkIndex] = bookmark;
        return;
      }

      state.bookmarks = [
        ...state.bookmarks,
        bookmark,
      ];
      state.activeBookmarkIndex = state.bookmarks.length - 1;
    },
    saveActiveBookmark: (state, { payload }: { payload: IVideoBookmark }) => {
      if (state.activeBookmarkIndex !== null) {
        state.bookmarks[state.activeBookmarkIndex] = {
          ...payload,
          saved: true,
        };
      }

      state.activeBookmarkIndex = null;
    },
    cancelActiveBookmark: (state) => {
      if (state.activeBookmarkIndex !== null && !state.bookmarks[state.activeBookmarkIndex].saved) {
        state.bookmarks = state.bookmarks.filter((_, index) => index !== state.activeBookmarkIndex);
      }

      state.activeBookmarkIndex = null;
    },
    removeActiveBookmark: (state) => {
      state.bookmarks = state.bookmarks.filter((_, index) => index !== state.activeBookmarkIndex);
      state.activeBookmarkIndex = null;
    },
    setActiveBookmark: (state, { payload }: { payload: number | null }) => {
      state.activeBookmarkIndex = payload === null ? null : payload;
    },
    hidePlayer: (state, { payload }: { payload: string }) => {
      state.videoRequest.cameras = state.videoRequest.cameras.map((camera) => {
        if (camera.name === payload) {
          return {
            ...camera,
            isPlayerHide: true,
          };
        }
        return camera;
      });
    },
    showPlayer: (state, { payload }: { payload: string }) => {
      state.videoRequest.cameras = state.videoRequest.cameras.map((camera) => {
        if (camera.name === payload) {
          return {
            ...camera,
            isPlayerHide: false,
          };
        }
        return camera;
      });
    },
    setViewLayout: (state, { payload }: { payload: TViewLayout }) => {
      state.viewLayout = payload;
    },
    reset: () => initialState,
  },
});

export default slice.reducer;
