/* eslint-disable no-param-reassign */
import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { IJob, IJobSearchRequest, TJobsFiltered } from '@th-common/interfaces/job';
import { IPagedResult } from '@th-common/interfaces/paged-result';
import dayjs from 'dayjs';

import { api as apiJobs } from './api';

export interface IJobState {
  jobs: IJob[];
  searchRequest: IJobSearchRequest;
  jobsListLoading: boolean;
  pagination: Omit<IPagedResult<IJob>, 'items'>;
  activeJobIndex: number | null;
  activeJobId: number | null;
  activeJob: IJob | null;
  lastTimeUpdated: string;
}

export const defaultFilters: TJobsFiltered = {
  jobType: null,
  jobState: null,
  userKey: null,
  excludeJobTypes: [],
  orderBy: [
    {
      fieldName: 'createTime',
      ascending: false,
    },
  ],
};

const initialState: IJobState = {
  jobs: [],
  searchRequest: {
    page: 1,
    pageSize: 36,
    searches: [],
    searchAsOneWord: true,
    ...defaultFilters,
  },
  jobsListLoading: false,
  pagination: {
    page: 1,
    pageCount: 0,
    totalCount: 0,
  },
  activeJob: null,
  activeJobId: null,
  activeJobIndex: null,
  lastTimeUpdated: dayjs().toISOString(),
};

export const slice = createSlice({
  name: 'job',
  initialState,
  reducers: {
    reset: () => ({
      ...initialState,
      lastTimeUpdated: dayjs().toISOString(),
    }),
    setJobsLoading: (state, { payload }: { payload: boolean }) => {
      state.jobsListLoading = payload;
    },
    resetPage: (state) => {
      state.jobs = [];
      state.searchRequest.page = 1;
    },
    setPage: (state, { payload }: { payload: number }) => {
      state.searchRequest.page = payload;
    },
    setActiveJobIndex: (state, { payload: jobIndex }: { payload: number | null }) => {
      if (jobIndex === null) {
        state.activeJob = null;
        state.activeJobId = null;
        state.activeJobIndex = null;
      } else {
        const job = state.jobs[jobIndex];

        if (job) {
          state.activeJob = job;
          state.activeJobId = job.id;
          state.activeJobIndex = jobIndex;
        }
      }
    },
    formFilter: (state, { payload }) => {
      state.searchRequest = {
        ...state.searchRequest,
        page: 1,
        userKey: payload.userKey === 'All' ? null : payload.userKey,
      };
    },
    search: (state, { payload }) => {
      state.searchRequest = {
        ...state.searchRequest,
        page: 1,
        searches: payload ? [payload] : [],
      };
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<IJobState>) => {
    builder
      .addMatcher(apiJobs.endpoints.getJobs.matchFulfilled, (state, { payload }) => {
        const { items, ...pagination } = payload;

        state.jobs = items || [];
        state.pagination = pagination;
        state.lastTimeUpdated = dayjs().toISOString();
      });
  },
});

export default slice.reducer;
