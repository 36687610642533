import { TReduxTag } from '@th-common/enums/redux-tags.enum';
import { IGeographicalQuery, IGeographicalQuerySearchRequest } from '@th-common/interfaces/geographical-query';
import { IPagedResult } from '@th-common/interfaces/paged-result';
import { apiRoot } from '@th-common/store/api';

export const api = apiRoot.enhanceEndpoints({}).injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getGeographicalQueries: builder.query<IPagedResult<IGeographicalQuery>, IGeographicalQuerySearchRequest>({
      query: (searchRequest: IGeographicalQuerySearchRequest) => {
        return {
          url: 'job/geographical-queries/all',
          method: 'POST',
          body: searchRequest,
        };
      },
      providesTags: [
        TReduxTag.GeographicalQueries,
      ],
    }),
  }),
});
