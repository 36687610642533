export enum TDateKind {
  Recent = 1,
  AllTime,
  Yesterday,
  LastWeek,
  LastMonth,
  ForDate,
  ForDateRange,
  LongTimeAgo,
}
