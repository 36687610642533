/* eslint-disable no-param-reassign */
import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { TJobType } from '@th-common/enums/job/job-type.enum';
import { IMatSelectDefaultOption } from '@th-common/interfaces/select';

import { lookupStore } from '../lookup';

interface IJobWizardPayload {
  type: TJobType;
  form?: unknown;
}
export interface IJobWizardState {
  jobTypeOpen: boolean;
  jobWizardOpen: TJobType | null;
  jobWizardForm: unknown | null;
  firmwareVersions: IMatSelectDefaultOption[];
  templateConfigurations: IMatSelectDefaultOption[];
}

const initialState: IJobWizardState = {
  jobTypeOpen: false,
  jobWizardOpen: null,
  jobWizardForm: null,
  firmwareVersions: [],
  templateConfigurations: [],
};

export const slice = createSlice({
  name: 'jobWizard',
  initialState,
  reducers: {
    reset: () => ({
      ...initialState,
    }),
    openJobWizard: (state, { payload }: { payload: IJobWizardPayload }) => {
      state.jobWizardOpen = payload.type;
      state.jobWizardForm = payload.form || null;
    },
    closeJobWizard: (state) => {
      state.jobWizardOpen = null;
      state.jobWizardForm = null;
    },
    openJobType: (state) => {
      state.jobTypeOpen = true;
    },
    closeJobType: (state) => {
      state.jobTypeOpen = false;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<IJobWizardState>) => {
    builder.addMatcher(lookupStore.api.endpoints.firmwareVersions.matchFulfilled, (state, { payload }) => {
      state.firmwareVersions = payload.map((item) => ({
        value: item.id,
        title: item.name,
      }));
    });
    builder.addMatcher(lookupStore.api.endpoints.templateConfigurations.matchFulfilled, (state, { payload }) => {
      state.templateConfigurations = payload.map((item) => ({
        value: item.id,
        title: item.name,
      }));
    });
  },
});

export default slice.reducer;
