/* eslint-disable @typescript-eslint/naming-convention */
export enum TLicensedFeature {
  /// <summary>
  /// IF medadata
  /// </summary>
  CADAVL_IF_METADATA = 1,
  /// <summary>
  /// VA data
  /// </summary>
  CADAVL_VA_DATA = 2,
  /// <summary>
  /// ridership validation jobs
  /// </summary>
  RIDERSHIP_VALIDATION = 4,
  /// <summary>
  /// cellular data
  /// </summary>
  CELLULAR = 8,
  // geographical queries
  GEO_QUERY = 16,
}
